import type { AppointmentFormTypes as Types } from '@grandvisionhq/appointments'

export const consents: Types.ConsentOptIn[] = [
  {
    title: 'appointments.consent.createAccount',
    value: 'createAccount',
    required: true,
  },
]

export const optIns: Types.ConsentOptIn[] = [
  // single optin
  {
    title: 'appointments.optIn.directMail',
    value: 'direct-mail',
  },
  // grouped optin is a single optin that is not submitted
  {
    title: 'appointments.optIn.allMarketingComms',
    value: 'allMarketingComms',
  },
]

/* these optins ( 'marketingPost', 'marketingEmails') are submitted
with value true if grouped optin is checked or false if is not checked.
(We also dont submit any optins if the user is not logged in)
The title (the text that the user saw) needs to be the same as the
grouped optin's title for all of them, since this is the only text that
the user saw and legally we are bind to remember what the user
agreed to */

export const groupedOptins = {
  allMarketingComms: ['marketingPost', 'marketingEmails'],
}
